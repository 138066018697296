import { Box, Card, Typography } from '@material-ui/core'
import { useLocation } from 'wouter'
import { noty } from '../../components/SnackbarQueue'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import useStore from '../../stores/useStore'
import QRCode from 'qrcode.react'
import { UiButton } from '../../ui'
import { formatCurrency, copyToClipboard } from '../../utils'
import { Timer } from '../../components'
import { getStatusTransaction } from '../../stores/services'

function PixQrCodePage() {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)
  const {
    sumWithFee,
    getPixObject,
    setPixPaymentCompleted,
    setResultMessage,
    isPixPayTimeExpired,
    getUrl,
  } = useStore()
  const pixObject = getPixObject()
  const [time, setTime] = useState(new Date(pixObject.pix_expiration_date).getTime())

  const checkPixStatus = async () => {
    try {
      setLoading(true)
      const response = await getStatusTransaction(getUrl())
      if (isPixPayTimeExpired) {
        setLoading(false)
        setLocation('/done/pixResult')
      } else if (response === null) {
        setLoading(false)
        noty.info('Aguardando pagamento.', { autoHideDuration: 4000 })
      } else if (response.status === false) {
        setResultMessage(response.message)
        setLoading(false)
        setLocation('/done/pixResult')
      } else {
        setPixPaymentCompleted(true)
        setLoading(false)
        setLocation('/done/paid')
      }
    } catch (error) {
      setLoading(false)
      noty.error(error)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(new Date(pixObject.pix_expiration_date).getTime())
      checkPixStatus()
    }, 10000)

    return () => clearTimeout(timer)
  })

  return (
    <>
      <AppBarContainer />

      <Box m={1}>
        <Box m={2}>
          <Typography variant="h6" align="center">
            {`Pague ${formatCurrency(sumWithFee)} em até `}
            <Timer expiryTimestamp={time} />
          </Typography>
        </Box>
        <Box m={3}>
          <Typography variant="body2" align="center">
            Abra seu internet banking e escaneie o QR code com a câmera do seu celular para realizar
            o pagamento.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Card style={{ width: '155px', height: '155px' }}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <QRCode value={pixObject.pix_qr_code} />
            </Box>
          </Card>
        </Box>
        <Box m={3}>
          <Typography variant="body2" align="center">
            Ou, se preferir, copie e cole a chave aleatória.
          </Typography>
        </Box>
        <Box m={2}>
          <UiButton
            variant="outlined"
            color="secondary"
            style={{ textTransform: 'none', border: '2px solid' }}
            onClick={() => {
              copyToClipboard(pixObject.pix_qr_code)
              noty.success('Chave copiada com sucesso!')
            }}>
            Pix Copia e Cola
          </UiButton>
        </Box>
        <Box m={2}>
          <UiButton
            variant="text"
            color="secondary"
            style={{ textTransform: 'none' }}
            disabled={loading}
            onClick={checkPixStatus}>
            {loading ? 'Verificando pagamento' : 'Já realizei o pagamento via Pix'}
          </UiButton>
        </Box>
      </Box>
    </>
  )
}

export default view(PixQrCodePage)
