import React from 'react'
import { view } from 'react-easy-state'
import UiMaskedInput from '../../../../ui/UiMaskedInput'
import { UiSelect } from '../../../../ui'
import { Grid } from '@material-ui/core'
import { COUNTRIES_DDI, MapCurrencyMask } from '../../../../utils'
import useStore from 'stores/useStore'

const MaskedInput = props => {
  const { getParticipant, updateParticipantDDI } = useStore()

  const participant = getParticipant(props.code)

  const ddi = participant.ddi

  const ddiMasks = {
    '+55': 'BRL',
    '+1': 'USD',
    '+351': 'EUR',
  }

  const handleChange = value => {
    updateParticipantDDI(props.code, value)
  }

  const mask = MapCurrencyMask[ddiMasks[ddi] ?? 'Unknown'] || MapCurrencyMask['BRL']

  return (
    <Grid container spacing={1}>
      <Grid item xs container alignContent="flex-end">
        <UiSelect
          label="País"
          value={ddi}
          onChange={handleChange}
          options={COUNTRIES_DDI}></UiSelect>
      </Grid>
      <Grid item xs={9} container alignContent="flex-end">
        <UiMaskedInput mask={mask} {...props} />
      </Grid>
    </Grid>
  )
}

export default view(MaskedInput)
