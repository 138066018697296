import instalmentOptions from '../../business/instalmentOptions'
import React from 'react'
import useStore from '../../stores/useStore'
import BoletoForm from './BoletoForm'
import { view } from 'react-easy-state'

function BoletoContainer() {
  const { selectedPaymentMethod, sumWithFee, boletoInput } = useStore()
  const instalments = instalmentOptions(selectedPaymentMethod.number_of_instalments, sumWithFee)

  return <BoletoForm input={boletoInput} instalments={instalments} />
}

export default view(BoletoContainer)
