import React from 'react'
import CardForm from './CardForm'
import instalmentOptions from '../../business/instalmentOptions'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'

function CardFormContainer() {
  const { selectedPaymentMethod, sumWithPassFee, fee, creditInput: input } = useStore()
  const haveFee = selectedPaymentMethod.payment_type === 'cartao parcelado acrescimo' ? true : false
  const instalments = instalmentOptions(
    selectedPaymentMethod.number_of_instalments,
    sumWithPassFee,
    haveFee,
    fee
  )

  return <CardForm input={input} instalments={instalments} />
}

export default view(CardFormContainer)
