import React, { useState, useEffect } from 'react'
import { connect } from 'formik'

import SelectFormik from '../../inputs/formik/SelectFormik'
import { UiCard, UiText as Text } from '../../ui'
import { formatCurrency, isEmpty } from '../../utils'
import { Content, ColOne } from './OptionalPage.styles'
import { IParticipant, IOptionalItem, IOptions, ISelectedOptionals } from './OptionalPage'
import useStore from '../../stores/useStore'
interface IProps {
  index?: number
  participant: IParticipant
  optionals: Array<IOptionalItem>
  optionalsParticipantList: Array<ISelectedOptionals>
  formik?: any
  fieldNames: any
  onChange: (
    participantOptionals: ISelectedOptionals,
    participantId: string,
    optionId: number,
    optionalId: number
  ) => void
}

const OptionalPageItem = ({
  index,
  participant,
  optionals,
  optionalsParticipantList,
  onChange,
  formik: { setFieldValue, setFieldTouched, errors, values },
  fieldNames,
}: IProps) => {
  const [participantOptionals, setParticipantOptionals] = useState<ISelectedOptionals>({
    participantId: participant.id,
    optionals: [],
  })

  const { isSameParticipant, calcFeeInCents, hasPassFee, isFree } = useStore()

  useEffect(() => {
    const optionalsParticipant = optionalsParticipantList.filter(
      value => value.participantId === participant.id
    )[0]
    const optionals = optionalsParticipant?.optionals
    const fieldName = fieldNames[participantId]
    optionals &&
      fieldName.forEach((field: string) => {
        setFieldValue(field, optionals[0]?.optionId)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantOptionals])

  useEffect(() => {
    const optionalsParticipant = optionalsParticipantList.filter(
      value => value.participantId === participant.id
    )[0]
    setParticipantOptionals(
      optionalsParticipant || {
        participantId: participant.id,
        optionals: [],
      }
    )
  }, [optionalsParticipantList, participant.id])

  const getOptionalLabel = (optionalId: number) =>
    optionals.find((opt: IOptionalItem) => opt.id === optionalId)?.label

  const formatOptionalValue = (amount_in_cents: number, isFree: boolean) => {
    let suffix = ''
    if (hasPassFee && !isFree) {
      suffix = ` (+ ${formatCurrency(calcFeeInCents(amount_in_cents))} taxa de serviço)`
    }

    return formatCurrency(amount_in_cents) + suffix
  }

  const mountOptions = (options: Array<IOptions>) => {
    const blank = [0, '']
    const newOptions = options?.map(({ id, label, amount_in_cents, soldout }: IOptions) => [
      id,
      `${label} - ${amount_in_cents ? formatOptionalValue(amount_in_cents, !isFree) : 'Grátis'} ${
        soldout ? 'Esgotado' : ''
      }`,
      soldout,
    ])
    return [blank, ...newOptions]
  }

  // const getOptionalSoldout = (options: Array<IOptions>): boolean => {
  //   const optionalSoldout = options?.every(({ soldout }: IOptions) => soldout)
  //   return optionalSoldout
  // }

  const getOption = (optionalId: number) => {
    const optional = participantOptionals?.optionals?.filter(
      opt => opt?.optionalId === optionalId
    )[0]
    return optional && optional.optionId
  }

  const handleChangeSelect = (optionId: number, optionalId: number, options: Array<IOptions>) => {
    const participantOptionId = getOption(optionalId)
    if (participantOptionId !== optionId) {
      const filterOption = (id: number) => {
        const filteredOption = options.filter(option => option.id === id)[0]
        const label = `${getOptionalLabel(optionalId)} - ${filteredOption?.label}`
        const amount_in_cents = filteredOption?.amount_in_cents
        const amount_fee_in_cents = calcFeeInCents(amount_in_cents)

        return {
          optionalId,
          optionId: filteredOption?.id,
          label,
          amount_in_cents,
          amount_fee_in_cents,
          qtde: 1,
        }
      }

      const filterOptions = (id: number) =>
        participantOptionals?.optionals?.filter(
          option => option.optionId !== id && option.optionalId !== optionalId
        )

      const newOption = filterOption(optionId)
      const newOptions = filterOptions(optionId)

      const optionsResult = newOptions ? [...newOptions, newOption] : [newOption]

      const filteredResult: ISelectedOptionals = {
        participantId: participant.id,
        optionals: optionsResult.filter(option => option.optionId),
      }
      onChange(filteredResult, participant.id, optionId, optionalId)
      setParticipantOptionals(filteredResult)
    }
  }

  const participantId = participant.id.replace(/-/g, '')

  return (
    <UiCard>
      <Content>
        <ColOne>
          {isSameParticipant ? (
            index === 0 && <Text marginBottom="10px">{participant.name}</Text>
          ) : (
            <Text marginBottom="10px">{participant.name}</Text>
          )}
          {optionals?.map(({ id: optionalId, label, required, options }, index) => {
            if (options && !isEmpty(fieldNames)) {
              const fieldName = fieldNames[participantId]
              return (
                <div key={`optionals-key-${optionalId}-${participantId}`}>
                  <Text fontSize="14px" marginTop="10px">
                    {label}
                  </Text>
                  <SelectFormik
                    // disabled={soldout}
                    // label={soldout && 'Esgotado'}
                    error={errors[fieldName[index]]}
                    helperText={errors[fieldName[index]]}
                    onBlur={setFieldTouched}
                    required={required}
                    name={fieldName[index]}
                    options={mountOptions(options)}
                    onChange={(optionId: number) => {
                      handleChangeSelect(optionId, optionalId, options)
                      setFieldValue(fieldName[index], optionId)
                    }}
                    value={getOption(optionalId)}
                  />
                </div>
              )
            }
          })}
        </ColOne>
      </Content>
    </UiCard>
  )
}

export default connect(OptionalPageItem)
