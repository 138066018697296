import React from 'react'
import { UiMaskedInput } from '../ui'

export default function ExpirationInput({ fourDigits = false, ...props }) {
  const MASK = fourDigits ? MASK4 : MASK2
  return (
    <UiMaskedInput
      inputProps={{ inputMode: 'numeric' }}
      mask={MASK}
      label="Validade (MM/AA)"
      required
      {...props}
    />
  )
}

const MASK2 = [/\d/, /\d/, '/', /\d/, /\d/]
const MASK4 = [/\d/, /\d/, '/', '2', '0', /\d/, /\d/]
