import React, { useCallback, useEffect } from 'react'
import { Route, useLocation } from 'wouter'
import { AuthPage } from './AuthPage'
import DebitPage from './DebitPage'
import { DonePage as DoneBoletoPage } from './done/DoneBoletoPage'
import { DonePage as DonePaidPage } from './done/DonePaidPage'
import ParticipantsPage from './ParticipantsPage'
import PaymentBoletoPage from './PaymentBoletoPage'
import PaymentCreditPage from './PaymentCreditPage'
import PaymentDebitPage from './PaymentDebitPage'
import PaymentPixPage from './PaymentPixPage'
import { PaymentPage } from './PaymentPage'
import TicketsPage from './TicketsPage'
import OptionalPage from './OptionalPage'
import HomePage from './HomePage'
import PixQrCodePage from './PixQrCodePage'
import PixResultPage from './PixResultPage'
import ErrorPage from './ErrorPage'
import ProcessingPage from './ProcessingPage'
import PixDocumentsPage from './PixDocumentsPage'
import useStore from 'stores/useStore'
import { GTM } from '../business/gtm/googleTagManager'
import { GA4 } from '../business/ga/googleAnalytics'
import PaymentCardInternationalPage from './PaymentCardInternationalPage'

export default function Pages() {
  const {
    getIsSubscriptionComplete,
    setIsSubscriptionComplete,
    regenerateCodes,
    resetCheckout,
    event,
  } = useStore()

  const [, setLocation] = useLocation()

  const handleLoad = useCallback(() => {
    if (getIsSubscriptionComplete()) {
      setIsSubscriptionComplete(false)
      regenerateCodes()
      resetCheckout()
      setLocation('/tickets')
    }
  }, [
    getIsSubscriptionComplete,
    setIsSubscriptionComplete,
    resetCheckout,
    regenerateCodes,
    setLocation,
  ])

  useEffect(() => {
    GTM.Initialize(event?.integrations?.google_tag_manager)
    GA4.Initialize(event?.integrations?.google_analytics)
  }, [event])

  useEffect(() => {
    window.addEventListener('popstate', handleLoad)
    return () => {
      window.removeEventListener('popstate', handleLoad)
    }
  }, [handleLoad, event])

  const Routes = (
    <>
      <Route path="/" component={HomePage} />
      <Route path="/tickets" component={TicketsPage} />
      <Route path="/optionals" component={OptionalPage} />
      <Route path="/auth/:slug*" component={AuthPage} />
      <Route path="/participants" component={ParticipantsPage} />
      <Route path="/payment/boleto/:id" component={PaymentBoletoPage} />
      <Route path="/payment/credit/:id" component={PaymentCreditPage} />
      <Route path="/payment/creditInternational/:id" component={PaymentCardInternationalPage} />
      <Route path="/payment/debit/:id" component={PaymentDebitPage} />
      <Route path="/payment/pix/:id" component={PaymentPixPage} />
      <Route path="/payment/pixDocuments/:id" component={PixDocumentsPage} />
      <Route path="/debit" component={DebitPage} />
      <Route path="/payment" component={PaymentPage} />
      <Route path="/done/boleto" component={DoneBoletoPage} />
      <Route path="/done/pix" component={PixQrCodePage} />
      <Route path="/done/paid" component={DonePaidPage} />
      <Route path="/done/pixResult" component={PixResultPage} />
      <Route path="/done/ErrorPage" component={ErrorPage} />
      <Route path="/done/ProcessingPage" component={ProcessingPage} />
    </>
  )

  return Routes
}
