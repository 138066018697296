import React, { useEffect } from 'react'
import './style.css'
import { formatCurrency } from '../../../utils'
import useStore from '../../../stores/useStore'
import GTM from 'business/gtm/googleTagManager'

export default function DetailsCell() {
  const {
    getParticipantOptionals,
    selectedCategories,
    sumWithFee,
    hasPassFee,
    isFree,
    hasCoupon,
    totalParticantsOptionals,
    selectedPaymentMethod,
    minimun_fee_cents,
    freeEventPercentage,
  } = useStore()

  const showServiceFee = hasPassFee && !isFree
  const showOptionalServiceFee = hasPassFee && isFree && totalParticantsOptionals > 0
  const showFeeColumn = showServiceFee || showOptionalServiceFee

  const gaItems = selectedCategories.map(el => ({
    ...el,
    item_id: el.id,
    item_name: el.name,
    item_category: el.name,
    price: Number(el.price / 100),
  }))
  const gaValueBrl = sumWithFee / 100

  useEffect(() => {
    GTM.ViewCart(gaItems, gaValueBrl)
  }, [gaItems, gaValueBrl])

  const getFeePercentage = () => {
    if (!hasPassFee) return 0
    if (isFree) return (freeEventPercentage || 0) / 100
    return (selectedPaymentMethod?.amount_fee_percentage || 0) / 100
  }

  const feePercentage = getFeePercentage()

  const getAllOptionals = () => {
    if (!getParticipantOptionals) return []
    return getParticipantOptionals.flatMap(participant => participant.optionals || [])
  }

  const calculateCategoryBaseFees = () => {
    if (isFree) return 0

    return selectedCategories.reduce((total, category) => {
      const price = category.price || 0
      const qty = category.qty || 1
      return total + Math.round(price * qty * feePercentage)
    }, 0)
  }

  const calculateOptionalBaseFees = () => {
    return getAllOptionals().reduce((total, optional) => {
      const price = optional.amount_in_cents || 0
      return total + Math.round(price * feePercentage)
    }, 0)
  }

  const calculateFeeInfo = () => {
    if (!hasPassFee) return { adjustmentFactor: 1 }

    const categoryFees = calculateCategoryBaseFees()
    const optionalFees = calculateOptionalBaseFees()
    const totalFees = categoryFees + optionalFees

    const useMinimumFee = totalFees > 0 && totalFees < minimun_fee_cents
    const adjustmentFactor = useMinimumFee ? minimun_fee_cents / totalFees : 1

    return { adjustmentFactor }
  }

  const { adjustmentFactor } = calculateFeeInfo()

  const getCategoryPrice = category => category.price || 0

  const calculateCategoryFee = category => {
    if (!hasPassFee || isFree) return 0

    const price = getCategoryPrice(category)
    if (!price) return 0

    const baseFee = Math.round(price * category.qty * feePercentage)
    return Math.round(baseFee * adjustmentFactor)
  }

  const calculateCategorySubtotal = category => {
    const price = getCategoryPrice(category)
    if (!price) return 0

    const baseSubtotal = price * category.qty
    if (!hasPassFee || (hasCoupon && isFree)) return baseSubtotal

    return baseSubtotal + calculateCategoryFee(category)
  }

  const calculateOptionalFee = optional => {
    if (!hasPassFee) return 0

    const price = optional.amount_in_cents || 0
    if (!price) return 0

    const baseFee = Math.round(price * feePercentage)
    return Math.round(baseFee * adjustmentFactor)
  }

  const calculateOptionalSubtotal = optional => {
    const price = optional.amount_in_cents || 0
    if (!price) return 0

    if (!hasPassFee || (hasCoupon && isFree)) return price

    return price + calculateOptionalFee(optional)
  }

  const renderCategoryFee = category => {
    if (showServiceFee) {
      return formatCurrency(calculateCategoryFee(category))
    }
    return formatCurrency(0)
  }

  const renderOptionalFee = optional => {
    if (isFree && hasPassFee) {
      return formatCurrency(calculateOptionalFee(optional))
    }

    if (showServiceFee) {
      return formatCurrency(calculateOptionalFee(optional))
    }

    return formatCurrency(0)
  }

  const getColumnWidth = column => {
    const widths = {
      item: '40%',
      quantity: '10%',
      price: '15%',
      fee: '20%',
      subtotalWithFee: '15%',
      subtotalWithoutFee: '35%',
    }

    return widths[column]
  }

  return (
    <div align="center" className="details-table">
      <span className="title">Resumo do Pedido</span>
      <table>
        <thead>
          <tr>
            <th style={{ width: getColumnWidth('item') }}>Item</th>
            <th style={{ textAlign: 'center', width: getColumnWidth('quantity') }}>Qde</th>
            <th style={{ width: getColumnWidth('price') }}>Preço</th>
            {showFeeColumn && <th style={{ width: getColumnWidth('fee') }}>Taxa de serviço</th>}
            <th
              style={{
                width: showFeeColumn
                  ? getColumnWidth('subtotalWithFee')
                  : getColumnWidth('subtotalWithoutFee'),
              }}>
              Subtotal
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedCategories.map(category => (
            <tr key={category.id}>
              <td>
                <div className="ellipsis" title={category.name}>
                  {category.name}
                </div>
              </td>
              <td style={{ textAlign: 'center' }}>{category.qty}</td>
              <td>{formatCurrency(category.price)}</td>
              {showFeeColumn && <td>{renderCategoryFee(category)}</td>}
              <td>{formatCurrency(calculateCategorySubtotal(category))}</td>
            </tr>
          ))}

          {getParticipantOptionals?.map(participant =>
            participant.optionals?.map(optional => (
              <tr key={optional.optionalId}>
                <td>
                  <div className="ellipsis" title={optional.label}>
                    {optional.label}
                  </div>
                </td>
                <td style={{ textAlign: 'center' }}>{optional.qtde}</td>
                <td>{formatCurrency(optional.amount_in_cents)}</td>
                {showFeeColumn && <td>{renderOptionalFee(optional)}</td>}
                <td>{formatCurrency(calculateOptionalSubtotal(optional))}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}
