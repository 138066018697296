import React from 'react'
import { view } from 'react-easy-state'
import UiMaskedInput from '../../../src/ui/UiMaskedInput'
import UiSelect from '../../../src/ui/UiSelect'
import { Grid } from '@material-ui/core'
import { COUNTRIES_DDI, MapCurrencyMask } from '../../../src/utils'

const MaskedCellPhoneInput = props => {
  const { onInput } = props

  const ddiMasks = {
    '+55': 'BRL',
    '+1': 'USD',
    '+351': 'EUR',
  }

  const mask =
    MapCurrencyMask[ddiMasks[onInput('ddi').value] ?? 'Unknown'] || MapCurrencyMask['BRL']

  return (
    <Grid container spacing={1}>
      <Grid item xs container alignContent="flex-end">
        <UiSelect
          label="País"
          value={onInput('ddi').value}
          onChange={onInput('ddi').onChange}
          options={COUNTRIES_DDI}></UiSelect>
      </Grid>
      <Grid item xs={9} container alignContent="flex-end">
        <UiMaskedInput mask={mask} {...props.onInput('phone')} />
      </Grid>
    </Grid>
  )
}

export default view(MaskedCellPhoneInput)
