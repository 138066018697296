export const GTM = {
  Initialize(gtmId) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
    document.head.appendChild(script)

    const noScript = document.createElement('noscript')
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    document.body.appendChild(noScript)
  },

  pushToDataLayer(eventObject) {
    window.dataLayer.push({ ecommerce: null })
    setTimeout(() => {
      window.dataLayer.push(eventObject)
    }, 100)
  },

  ViewItemList(items) {
    this.pushToDataLayer({
      event: 'view_item_list',
      ecommerce: {
        item_list_name: 'Tickets',
        item_list_id: 'tickets',
        items: items,
      },
    })
  },

  AddToCart(item, value) {
    this.pushToDataLayer({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'BRL',
        value: value,
        items: [{ ...item }],
      },
    })
  },

  RemoveFromCart(item, value) {
    this.pushToDataLayer({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'BRL',
        value: value,
        items: [{ ...item }],
      },
    })
  },

  ViewCart(items, value) {
    this.pushToDataLayer({
      event: 'view_cart',
      ecommerce: {
        currency: 'BRL',
        items: items,
        value: value,
      },
    })
  },

  BeginCheckout(items, value, coupon) {
    this.pushToDataLayer({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'BRL',
        value: value,
        items: items,
        coupon: coupon,
      },
    })
  },

  AddPaymentInfo(items, value, coupon, payment_type) {
    this.pushToDataLayer({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'BRL',
        value: value,
        coupon: coupon,
        payment_type: payment_type,
        items: items,
      },
    })
  },

  Purchase(items, value, coupon, leader) {
    this.pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        transaction_id: leader.id,
        value: value,
        tax: 0,
        shipping: 0,
        currency: 'BRL',
        coupon: coupon,
        items: items,
        leader,
      },
    })
  },
}

export default GTM
