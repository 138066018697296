import React, { useState } from 'react'
import { Typography, Collapse, CardContent } from '@material-ui/core'
import { formatCurrency, PaidOrFree } from '../../utils'
import StepInput from '../../inputs/StepInput'
import { CategoryItemLayout } from './CategoryItemLayout'
import styled from 'styled-components'
import { UiLink } from '../../ui'
import useStore from 'stores/useStore'

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 70px;
`

const ColOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ColTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
type TypographyProps = {
  size?: string
  weight?: string
}

const TypographyStyled = styled(Typography)`
  && {
    font-size: ${(props: TypographyProps) => (props.size ? props.size : '13px')};
    font-weight: ${(props: TypographyProps) => (props.weight ? props.weight : '400')};
  }
`

const UiLinkStyled = styled(UiLink)`
  && {
    margin-top: 6px;
  }
`

const CardContentStyled = styled(CardContent)`
  && {
    margin: 0px;
    &&.MuiCardContent-root:last-child {
      padding-bottom: 8px;
    }
  }
`

type CategoryItemProps = {
  id?: string | number
  qty: number
  name?: string
  price: number
  limit: number
  onChange: any
  visible: boolean
  list_of_coupons: any
  soldOut: any
  min_tickets: number | null
  paymentMethods: {
    id: number
    payment_type: string
    amount_in_cents: number
    number_of_instalments: number
    begin_at: string
    end_at: string
    price: number
  }[]
}

export function CategoryItem({
  qty = 0,
  name = 'Entrada',
  price = 0,
  limit = 1,
  onChange,
  visible,
  soldOut = false,
  min_tickets = null,
  list_of_coupons: coupons,
}: CategoryItemProps) {
  const { calcFeeInCents, hasPassFee } = useStore()
  const [toggle, setToggle] = useState(false)

  const handleToggle = () => {
    setToggle(!!!toggle)
  }

  const valueSuffix =
    hasPassFee && price ? ` (+ ${formatCurrency(calcFeeInCents(price))} taxa de serviço)` : ''

  return (
    <CategoryItemLayout showTag={!visible}>
      <Content>
        <ColOne style={{ width: '75%' }}>
          <Typography variant="h6" noWrap>
            {name}
          </Typography>
          <Typography>
            <PaidOrFree value={price} />
            <Typography variant="inherit" style={{ whiteSpace: 'nowrap' }}>
              {valueSuffix}
            </Typography>
          </Typography>
        </ColOne>
        <ColTwo>
          {!soldOut ? (
            <StepInput
              value={qty}
              onChange={onChange}
              max={limit}
              minTickets={min_tickets}
              name={name}
              price={price}
            />
          ) : (
            'Esgotado'
          )}
        </ColTwo>
      </Content>
      {coupons.length >= 1 && visible && (
        <Collapse in={toggle} timeout="auto" unmountOnExit>
          <CardContentStyled>
            {coupons.length >= 1 && visible && (
              <>
                <TypographyStyled>
                  Aplique um cupom de desconto para desbloquear esta categoria
                </TypographyStyled>
              </>
            )}
          </CardContentStyled>
        </Collapse>
      )}
      {coupons.length >= 1 && visible && (
        <UiLinkStyled href="#" onClick={handleToggle}>
          {toggle ? 'Ocultar detalhes' : 'Ver detalhes'}
        </UiLinkStyled>
      )}
    </CategoryItemLayout>
  )
}
