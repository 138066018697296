import { IconButton, Snackbar } from '@material-ui/core'
import { CheckCircleIcon, CloseIcon, ErrorIcon, InfoIcon, WarningIcon } from '../icons'
import React from 'react'

const UiSnackbar = ({ closeable, variant, onClose, ...props }) => {
  props = addVariant(variant, closeable, onClose, props)
  return <Snackbar {...props} onClose={onClose} />
}

const ICONS = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const COLORS = {
  success: '#43a047',
  warning: '#ffa000',
  error: '#d32f2f',
  info: '#1976d2',
}

const TITLES = {
  success: 'Sucesso',
  warning: 'Atenção',
  error: 'Algo deu errado',
  info: 'Informação',
}

function addVariant(variant, closeable, onClose, props) {
  if (!(variant && ICONS[variant])) return props
  const Icon = ICONS[variant]
  const backgroundColor = COLORS[variant] || 'inherit'
  const title = TITLES[variant] || ''

  const ContentProps = {
    ...props.ContentProps,
    style: {
      ...(props.ContentProps?.style || {}),
      backgroundColor,
      padding: '16px',
      position: 'relative',
    },
  }

  const closeButton = closeable ? (
    <IconButton
      key="close"
      color="inherit"
      onClick={e => onClose(e, 'clickbutton')}
      style={{ position: 'absolute', top: '8px', right: '8px' }}>
      <CloseIcon />
    </IconButton>
  ) : null

  const message = (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '8px' }}>
        <Icon style={{ flexShrink: 0 }} />
        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{title}</div>
      </div>
      <div style={{ marginTop: '4px', fontSize: '14px' }}>{props.message}</div>
      {closeButton}
    </div>
  )

  return { ...props, message, ContentProps }
}

export default UiSnackbar
