import { Box, Typography } from '@material-ui/core'
import { useLocation } from 'wouter'
import React, { useEffect, useState } from 'react'
import { view } from 'react-easy-state'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import { UiButton } from '../../ui'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import useStore from '../../stores/useStore'
import CardInternationalDetails from './CardInternationalDetails'
import { noty } from 'components/SnackbarQueue'
import { getStatusTransaction } from 'stores/services'

function PaymentCardInternationalPage({ params: { id } }: any) {
  const { setPaymentMethodId, getCardInternational, setResultMessage, regenerateCodes } = useStore()
  const [date, setDate] = useState(Date.now())
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [creditCardState, setCreditCardState] = useState({
    url: '',
    nextUrl: '',
  })

  const checkTransactionStatus = async () => {
    try {
      const response = await getStatusTransaction(creditCardState.url)
      if (response === null) {
        console.log(date)
      } else if (response.status === false) {
        setResultMessage(response.message)
        setLocation('/done/ErrorPage')
      } else {
        setLocation('/done/paid')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDate(Date.now())
      creditCardState.url !== '' && checkTransactionStatus()
    }, 1000)

    return () => clearTimeout(timer)
  })

  useEffect(() => {
    setPaymentMethodId(id)
  }, [setPaymentMethodId, id])

  useEffect(() => {
    const processPayment = async () => {
      await pay()
    }
    processPayment()
  }, [])

  async function pay() {
    try {
      setLoading(true)
      setHasError(false)
      const url = await getCardInternational()
      setCreditCardState(url)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setHasError(true)
      if (e instanceof Error) {
        if (e.message === 'Erro Desconhecido!')
          noty.error('Falha no pagamento. Infelizmente não foi possível realizar o pagamento.')
        else noty.error(e.message)
      }
    }
  }

  const handleRetryClick = () => {
    setLocation('/tickets')
    regenerateCodes()
  }

  return (
    <>
      <AppBarContainer />
      <Box m={1}>
        <Box m={2}>
          <Typography variant="h6" align="center">
            Pagamento via parcelamento
          </Typography>
          <Typography variant="body2" align="center">
            Você será direcionado para um ambiente seguro para concluir o pagamento
          </Typography>
        </Box>
        <CardInternationalDetails />
      </Box>
      <FooterBarContainer>
        <UiBusyWrapper busy={loading} uid="PaymentPixPage">
          {hasError ? (
            <UiButton onClick={handleRetryClick}>Tentar novamente</UiButton>
          ) : (
            <UiButton
              href={creditCardState.nextUrl || undefined}
              target={creditCardState.nextUrl ? '_blank' : undefined}>
              {loading ? 'Carregando checkout' : 'AVANÇAR'}
            </UiButton>
          )}
        </UiBusyWrapper>
      </FooterBarContainer>
    </>
  )
}

export default view(PaymentCardInternationalPage)
