import React from 'react'
import {
  ListItem,
  ListItemIcon,
  Paper,
  ListItemText,
  Box,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Link } from 'wouter'
import { formatCurrency } from '../../utils'
import { paymentTypeMap } from './paymentTypeMap'
import { KeyboardArrowRightIcon } from '../../icons'
import { InstalmentsDetails } from './InstalmentsDetails'
import instalmentOptions from '../../business/instalmentOptions'
import useStore from 'stores/useStore'
import GTM from '../../business/gtm/googleTagManager'

type ListItemProps = {
  payment_type: string
  number_of_instalments: number
  price: number
  id: number | string
}

export function PaymentListItem({
  payment_type = 'boleto',
  number_of_instalments = 1,
  price = 0,
  id = 1,
}: ListItemProps) {
  const {
    fee,
    leaderParticipant,
    selectedCategories,
    enabledCoupons,
    hasPassFee,
    calcFeeInCents,
    isFree,
    minimun_fee_cents,
  } = useStore()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const paymentType = paymentTypeMap[payment_type]
  const PaymentTypeIcon = paymentType.icon
  const label = paymentType.label
  const amountLabel = buildAmountLabel(price, number_of_instalments, payment_type)
  const href =
    payment_type === 'pix' && !['cpf', 'cnpj'].includes(leaderParticipant.document_type)
      ? '/payment/pixDocuments/' + id
      : paymentType.url + id

  const gaItems = selectedCategories.map((el: any) => {
    return {
      ...el,
      item_id: el.id,
      item_name: el.name,
      item_category: el.name,
      price: Number(el.price / 100),
    }
  })

  const handleClick = (paymentType: any) => {
    GTM.AddPaymentInfo(gaItems, Number(price / 100), enabledCoupons.join(', '), paymentType.label)
  }

  function getPriceWithFee(price: number) {
    let sum = Math.floor(price)

    if (hasPassFee) {
      if (isFree) {
        sum += calcFeeInCents(price)
      } else {
        const feeInCents = calcFeeInCents(price)
        const totalFee = feeInCents

        sum += Math.max(totalFee, minimun_fee_cents)
      }
    }

    return sum
  }

  return (
    <Box mb={1}>
      <Paper style={{ position: 'relative', padding: 6 }}>
        <div style={link}>
          <KeyboardArrowRightIcon color="secondary" fontSize="large" />
        </div>
        <Link href={href} style={link} onClick={() => handleClick(paymentType)}></Link>
        <ListItem style={{ pointerEvents: 'none', padding: isMobile ? '6px' : '16px' }}>
          <ListItemIcon style={{ justifyContent: 'center' }}>
            <PaymentTypeIcon />
          </ListItemIcon>
          <ListItemText
            //@ts-ignore
            secondaryTypographyProps={secondary}
            primary={label}
            style={{
              paddingLeft: isMobile ? '0px' : '10px',
              paddingRight: isMobile ? '20px' : '0px',
            }}
            secondary={
              <>
                <Typography variant="body2" gutterBottom>
                  {amountLabel}{' '}
                  {hasPassFee && (
                    <span>(+{formatCurrency(getPriceWithFee(price) - price)} taxa de serviço)</span>
                  )}
                </Typography>
                {number_of_instalments > 1 && (
                  <InstalmentsDetails
                    instalmentOptions={instalmentOptions(
                      number_of_instalments,
                      getPriceWithFee(price),
                      payment_type === 'cartao parcelado acrescimo',
                      fee
                    )}
                  />
                )}
              </>
            }
          />
        </ListItem>
      </Paper>
    </Box>
  )
}

const secondary: TypographyProps & { component: React.ElementType } = {
  component: 'div',
}

const link: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}

function buildAmountLabel(
  amount_in_cents: number,
  number_of_instalments: number,
  paymentType: string
) {
  const formatted_amount = formatCurrency(amount_in_cents)
  if (paymentType === 'cartao parcelado internacional') {
    return `${formatted_amount} parcelado`
  }
  if (amount_in_cents === 0) return 'GRATUITO'
  if (number_of_instalments === 1) return `${formatted_amount} à vista`
  return `${formatted_amount} em até ${number_of_instalments}x`
}
