import { Typography, Card, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SuccessIcon } from '../../icons'
import React from 'react'

const useStyles = makeStyles({
  grid: {
    paddingBottom: '16px',
  },
  card: {
    padding: '16px 16px 8px 0px',
  },
  icon: {
    alignSelf: 'center',
    textAlign: 'center',
  },
  info: {
    alignSelf: 'center',
    textAlign: 'center',
    paddingBottom: '16px',
    fontWeight: 500,
  },
})

export default function CardInternationalDetails() {
  const classes = useStyles()

  const cardDetailsArray = [
    {
      icon: <SuccessIcon />,
      text: 'Você precisará inserir seu telefone de sua região',
    },
    {
      icon: <SuccessIcon />,
      text: 'Escolher em quantas vezes deseja parcelar',
    },
    {
      icon: <SuccessIcon />,
      text: 'Preencher as informações do seu cartão de crédito',
    },
  ]

  return (
    <Card className={classes.card}>
      <Typography className={classes.info} variant="body2">
        Na próxima etapa
      </Typography>
      {cardDetailsArray.map(element => (
        <Grid container className={classes.grid} key={`item-${element.text}`}>
          <Grid item xs={2} className={classes.icon}>
            {element.icon}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2">{element.text}</Typography>
          </Grid>
        </Grid>
      ))}
    </Card>
  )
}
