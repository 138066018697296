import React from 'react'
import Hbox from '../../ui/UiHbox'
import { Box, Typography } from '@material-ui/core'
import { formatCurrency, PaidOrFree } from '../../utils'
import Pluralize from 'react-pluralize'
import styled from 'styled-components'
import { UiLink } from '../../ui'
import useStore from 'stores/useStore'
import calcLeaderDiscount from 'business/groupDiscount/calcLeaderDiscount'
import calcGroupDiscount from 'business/groupDiscount/calcGroupDiscount'

const UiLinkStyled = styled(UiLink)`
  && {
    margin-left: 5px;
    white-space: nowrap;
  }
`

const PluralizeStyled = styled(Pluralize)`
  && {
    font-weight: 500;
  }
`

const Total = styled(Box)`
  && {
    font-weight: 500;
  }
`

const Coupon = styled(Typography)`
  && {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    margin: 2px;
  }
`

function ResumeFragment({ qty = 0, total = 0, drawer, handleDrawer }) {
  qty = qty || 0
  total = total || 0

  const { groupDiscount, selectedCategories } = useStore()
  const lider = calcLeaderDiscount(groupDiscount, selectedCategories)

  const grupo = calcGroupDiscount(groupDiscount, selectedCategories, lider)

  return (
    <Hbox>
      <Total display="block" align="right">
        {lider !== 0 && lider !== null && lider && (
          <Coupon>CUPOM DE DESCONTO: -{formatCurrency(lider)}</Coupon>
        )}
        {grupo !== 0 && grupo && <Coupon>DESCONTO DO GRUPO: -{formatCurrency(grupo)}</Coupon>}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="subtitle2">
            <PluralizeStyled singular="item" plural="itens" count={qty} />
            <UiLinkStyled href="#" onClick={handleDrawer}>
              {drawer ? 'Ocultar detalhes' : 'Ver detalhes'}
            </UiLinkStyled>
          </Typography>
          <PaidOrFree value={total} />
        </Box>
      </Total>
    </Hbox>
  )
}

export default ResumeFragment
