import React from 'react'
import { BoletoIcon, CreditIcon, DebitIcon, PixIcon } from '../../icons'

export const paymentTypeMap: {
  [string: string]: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    label: string
    url: string
  }
} = {
  boleto: {
    icon: BoletoIcon,
    label: 'Boleto bancário',
    url: '/payment/boleto/',
  },
  cartao: {
    icon: CreditIcon,
    label: 'Cartão de crédito',
    url: '/payment/credit/',
  },
  debito: {
    icon: DebitIcon,
    label: 'Débito Internet Banking',
    url: '/payment/debit/',
  },
  'cartao parcelado acrescimo': {
    icon: CreditIcon,
    label: 'Cartão de crédito com acréscimo',
    url: '/payment/credit/',
  },
  'cartao parcelado internacional': {
    icon: CreditIcon,
    label: 'Cartão de crédito parcelado',
    url: '/payment/creditInternational/',
  },
  pix: {
    icon: PixIcon,
    label: 'Pix',
    url: '/payment/pix/',
  },
}
