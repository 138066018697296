import React, { useState } from 'react'
import { view } from 'react-easy-state'
import { Box, Card, CardContent } from '@material-ui/core'
import { ParticipantChooser } from './ParticipantChooser'
import ParticipantForm from './ParticipantForm'
import CustomFields from './CustomFieldsModule'
import Title from './Title'
import { RemoveIcon } from '../../icons'
import useStore from '../../stores/useStore'
import { Spinner } from '../../ui'
import styled from 'styled-components'

type ItemProps = {
  id: string
  position: number
  categoryLabel: string
  isLeader: boolean
  participantInput: any
  fieldsInput: any
  setParticipantById: any
}

function ParticipantItem({
  id,
  position,
  categoryLabel,
  isLeader,
  participantInput = console.warn,
  fieldsInput,
  setParticipantById,
}: ItemProps) {
  const [isLoading, setIsLoading] = useState(false)

  const { hasParticipantsList, participantsShowForm, removeParticipant } = useStore()
  const showFormState = participantsShowForm[id]
  const showForm = !hasParticipantsList || showFormState

  function setShowForm(state: any) {
    participantsShowForm[id] = state
  }

  function excluir() {
    removeParticipant(id)
  }

  const Form = (
    <>
      <ParticipantForm input={participantInput} />
      <CustomFields code={id} {...fieldsInput} />
    </>
  )

  const Content = styled.div`
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    left: 0;
    top: 0;
  `

  const Chooser = hasParticipantsList && (
    <>
      {isLoading ? (
        <Content>
          <Spinner
            color="secondary"
            size={40}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: -12,
              marginLeft: -12,
            }}
          />
        </Content>
      ) : (
        <ParticipantChooser
          onChange={async (id: any) => {
            try {
              setIsLoading(true)
              await setParticipantById(id)
              setIsLoading(false)
              setShowForm(true)
            } catch {
              setIsLoading(false)
            }
          }}
        />
      )}
    </>
  )

  return (
    <Layout>
      {showForm && Chooser && <Trash onClick={excluir} />}

      <Title id={id} position={position} categoryLabel={categoryLabel} isLeader={isLeader} />

      {showForm ? Form : Chooser}
    </Layout>
  )
}

function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Box m={1}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  )
}

function Trash(props: any) {
  return <RemoveIcon color="error" style={{ float: 'right' }} {...props} />
}

export default view(ParticipantItem)
