import { store } from 'react-easy-state'
import api from '../store/api'
import { parse } from 'query-string'
import calcLeaderDiscount from '../business/groupDiscount/calcLeaderDiscount'
import calcGroupDiscount from '../business/groupDiscount/calcGroupDiscount'
import { customerCode } from '../business/customeCode'
import { generateCode, mapExistingFields } from '../utils'
import isParticipantInvalid from '../business/participant/isParticipantInvalid'
import hasInvalidField from '../business/participant/hasInvalidField'
import isDocumentValid from '../utils/isDocumentValid'
import Services from './services'
import fieldHashToArray from '../business/participant/fieldHashToArray'
import fixture from '../test/fixtures/feat-documents.json'
import { DEV, HOST_URL } from '../env'
import {
  loadUserParticipantsList,
  calculateInterestAmount,
  validateEnrollment,
  defaultPaymentMethods,
  GA4,
} from '../business'

import { sessionStorage } from '../safe-storage'
import { getToken } from '../business/authentication'
import isPhoneValid from 'utils/isPhoneValid'
import { postLog } from 'logflare/postLog'

// import pagarmeTransaction from 'test/fixtures/pagarmeTransactionCredit'
// import pagarmeBoleto from 'test/fixtures/pagarmeBoleto'

class Store {
  event = {}
  cart = {}
  coupon = ''
  enabledCoupons = []
  currentPaymentMethod = null

  participantsShowForm = {}
  url_status = ''
  leaderPosition = 1
  isSameParticipant = false
  showErrors = false

  enableErrors() {
    this.showErrors = true
  }

  toggleSameParticipant() {
    this.isSameParticipant = !this.isSameParticipant
  }

  participantsList = loadUserParticipantsList()
  isLoadingParticipant = false
  toggleLoadingParticipant() {
    this.isLoadingParticipant = !this.isLoadingParticipant
  }
  //[
  // {
  //   id: 505208,
  //   name: 'MARCOS JOVENIL',
  //   email: 'marcosjovenil@gmail.com',
  //   document_type: 'cpf',
  //   document_value: '99877970163'
  // }
  //]

  //PIX
  pixObject = {
    id: null,
    pix_qr_code: '',
    pix_expiration_date: '',
    name: null,
    document_type: null,
    document_value: null,
  }

  isPixPayTimeExpired = false
  isPixPaymentCompleted = false
  resultMessage = ''
  isAddedToCartOnce = false
  isSubscriptionComplete = false

  resetCheckout() {
    this.cart = {}
    this.participantOptionals = null
  }

  setIsSubscriptionComplete(value) {
    this.isSubscriptionComplete = value
  }
  getIsSubscriptionComplete() {
    return this.isSubscriptionComplete
  }

  setIsAddedToCartOnce() {
    this.isAddedToCartOnce = true
  }

  setPixObject(pixObject) {
    this.pixObject = pixObject
  }

  getPixObject() {
    return this.pixObject
  }

  getPaymentMethod() {
    return this.currentPaymentMethod
  }

  setPixPayTimeExpired(isPixPayTimeExpired) {
    this.isPixPayTimeExpired = isPixPayTimeExpired
  }

  getPixPayTimeExpired() {
    return this.isPixPayTimeExpired
  }

  setPixPaymentCompleted(isPixPaymentCompleted) {
    this.isPixPaymentCompleted = isPixPaymentCompleted
  }

  getPixPaymentCompleted() {
    return this.isPixPaymentCompleted
  }

  setResultMessage(resultMessage) {
    this.resultMessage = resultMessage
  }

  getResultMessage() {
    return this.resultMessage
  }

  calcDiscount(price, discount) {
    return (price * discount) / 100
  }

  setAmount(enrollment) {
    const category = this.selectedCategories
      .filter(category => category.id === enrollment.categoryId)
      .pop()
    const price = category?.price ? category.price : 0

    if (!this.groupDiscount) {
      enrollment.amount = parseFloat(price / 100).toFixed(2)
      return enrollment
    }

    const qtys = this.selectedCategories.map(o => o.qty).reduce((a, b) => Number(a) + Number(b), 0)
    if (qtys < this.groupDiscount.min_group_size) {
      enrollment.amount = parseFloat(price / 100).toFixed(2)
      return enrollment
    } else {
      if (this.groupDiscount.leader_free && enrollment.isLeader) {
        enrollment.amount = 0
        return enrollment
      } else {
        if (this.groupDiscount.amount_value_in_cents) {
          enrollment.amount = (price - this.groupDiscount.amount_value_in_cents) / 100
          return enrollment
        } else {
          enrollment.amount = (price - (price * this.groupDiscount.percentage_value) / 100) / 100
          return enrollment
        }
      }
    }

    // const lider = calcLeaderDiscount(this.groupDiscount, this.selectedCategories)
    // const grupo = calcGroupDiscount(this.groupDiscount, this.selectedCategories, lider, true)

    // if (enrollment.isLeader) {
    //   enrollment.amount = parseFloat(((lider ? price - lider : price) / 100).toFixed(2))
    // } else {
    //   enrollment.amount = parseFloat(
    //     (
    //       (grupo?.discount
    //         ? price - this.calcDiscount(price, this.groupDiscount.percentage_value)
    //         : price) / 100
    //     ).toFixed(2)
    //   )
    // }

    // return enrollment
  }

  addToken = () => {
    let token = getToken()
    return token ? `Bearer ${token}` : ''
  }

  sanitizeZipcode(fields) {
    let zipcodeId
    this.event.fields.forEach(field => {
      if (field.kind === 'zipcode') {
        zipcodeId = String(field.id)
      }
    })
    return fields.map(field => {
      if (field.field_id === zipcodeId) {
        return {
          ...field,
          value: field.value.replace('-', ''),
        }
      }
      return field
    })
  }

  get enrollment() {
    const withAmount = this.setAmount(this.leaderParticipant)
    const enrollment = {
      ...withAmount,
      fields: { ...this.leaderParticipant.fields },
    }

    enrollment.code = enrollment.id
    delete enrollment.id
    enrollment.fields = fieldHashToArray(enrollment.fields)
    enrollment.fields = this.sanitizeZipcode(enrollment.fields)
    delete enrollment.fieldsInput

    const participantsWithAmount = this.groupParticipants.map(participant =>
      this.setAmount(participant)
    )
    const participants = participantsWithAmount.map(participant =>
      this.participantDocOptional(participant)
    )

    if (!this.isDocRequired && enrollment?.document_value === '') {
      enrollment.document_type = 'passport'
      enrollment.document_value = '0'
    }

    return {
      Authorization: this.addToken(),
      event_id: this.event_id,
      enrollment,
      enrollments: participants,
    }
  }

  participantDocOptional(participant) {
    return !this.isDocRequired
      ? { ...participant, document_type: 'passport', document_value: '0' }
      : participant
  }

  async enrollByPix() {
    const payload = {
      ...this.enrollment,
      payment: {
        payment_method_id: !this.event.free ? this.payment_method_id : null,
        payment_method: this.payment_method,
        number_of_instalments: 1,
        amount: this.sumWithFee / 100,
      },
      event: this.event,
    }

    const data = {
      amount: Math.trunc(this.sumWithFee),
      free: this.event.free,
      customer: {
        email: this.leaderParticipant.email,
        document_number: this.pixObject.document_value || this.leaderParticipant.document_value,
      },
      metadata: { z: Object.assign(this.metadata, payload) },
    }
    data.metadata.front_end = 'fluxo-inscricao'
    data.metadata.host_url = HOST_URL

    const enrollment = await validateEnrollment({ payload: payload })

    if (enrollment.valid && enrollment.message.length === 0) return Services.pagarmePix(data)
    else throw new Error(enrollment.message)
  }

  //PIX

  participantOptionals = null

  setParticipantOptionals(optionals) {
    this.participantOptionals = optionals
  }

  get getParticipantOptionals() {
    return this.participantOptionals
  }

  get getParticipantsList() {
    return this.participants?.map(({ id, name }) => {
      return { id, name }
    })
  }

  get avaliableCoupons() {
    return this.categories.map(c => c.list_of_coupons).flat()
  }

  removeCoupon(coupon) {
    this.enabledCoupons = this.enabledCoupons.filter(c => c !== coupon)
    this.setCoupon(coupon)
    this.removeCouponCategoriesFromCart()
  }

  get leaderIndex() {
    return Math.max(this.leaderPosition - 1, 0)
  }

  get leaderParticipant() {
    if (this.participants.length === 0) {
      this.buildParticipants()
    }
    return this.participants[this.leaderIndex] || {}
  }

  get groupParticipants() {
    const leader = this.leaderParticipant
    const leaderCode = leader.id
    return this.participants
      .filter(p => p.id !== leaderCode)
      .map(p => {
        const gp = this.isSameParticipant
          ? {
              ...leader,
              id: p.id,
              discount_amount_value: p.discount_amount_value,
              discount_percentage_value: p.discount_percentage_value,
              discount_category: p.discount_category,
              discount_id: p.discount_id,
              categoryLabel: p.categoryLabel,
              categoryId: p.categoryId,
              isLeader: p.isLeader,
              coupon: p.coupon,
              position: p.position,
            }
          : { ...p }
        gp.code = gp.id
        delete gp.id
        if (!Array.isArray(gp.fields)) {
          gp.fields = fieldHashToArray(gp.fields)
        }
        delete gp.fieldsInput
        return gp
      })
  }

  get hasParticipantsList() {
    return this.participantsList?.length > 0
  }

  get participantsOptions() {
    return this.participantsList
  }

  get getLoadingParticipant() {
    return this.isLoadingParticipant
  }

  userData = JSON.parse(sessionStorage.getItem('userData')) || {}
  participants = JSON.parse(sessionStorage.getItem('userDataParticipantsData')) || []

  debit = {
    number: '',
    name: '',
    expiration: '',
    tid: generateCode(),
  }

  credit = {
    number: '',
    name: '',
    expiration: '',
    cvv: '',
    instalments: 0,
    document_type: '',
    document_value: '',
    phone: '',
    country: '',
    ddi: '+55',
  }

  isInternationalCreditCard = false

  toggleIsInternationalCreditCard() {
    this.isInternationalCreditCard = !this.isInternationalCreditCard
  }

  setUrl(url) {
    this.url_status = url
  }

  getUrl() {
    return this.url_status
  }

  boleto = {
    name: '',
    document_type: '',
    document_value: '',
    instalments: 0,
  }

  pagarmeTransaction = null //pagarmeTransaction

  // Função para calcular a taxa de serviço
  calculateFee(sum) {
    let fee = 0

    if (this.hasPassFee) {
      if (this.isFree) {
        fee += this.calcFeeInCents(sum)
      } else {
        const feeInCents = this.calcFeeInCents(sum)
        const totalFee = feeInCents

        // Aplica a taxa mínima se necessário
        fee += Math.max(totalFee, this.minimun_fee_cents)
      }
    }
    return fee
  }

  // Função para calcular o valor total com a taxa de serviço e a taxa de parcelamento
  get sumWithFee() {
    let sum = Math.round(this.sum) // Math.round para garantir que vai um amount em centavos sem pt flutuante.
    sum += this.calculateFee(sum)

    if (this.selectedPaymentMethod.payment_type === 'cartao parcelado acrescimo') {
      const sumWithAcrescFee = calculateInterestAmount(sum, this.credit.instalments, this.fee)
      // A soma acima pode ficar como NaN pq o cliente não escolheu o parcelamento ainda.
      if (sumWithAcrescFee) sum = sumWithAcrescFee
    }

    if (sum < 0) sum = 0
    return sum
  }

  // Função para calcular o valor total com a taxa de serviço
  get sumWithPassFee() {
    let sum = Math.round(this.sum)
    sum += this.calculateFee(sum)
    if (sum < 0) sum = 0
    return sum
  }

  isBoletoInitiated = false

  initBoleto() {
    if (!this.isBoletoInitiated) {
      if (!this.boleto.name) {
        this.boleto.name = this.leaderParticipant.name
      }
      if (
        !this.boleto.document_value &&
        ['cpf', 'cnpj'].includes(this.leaderParticipant.document_type)
      ) {
        this.boleto.document_type = this.leaderParticipant.document_type
        this.boleto.document_value = this.leaderParticipant.document_value
      }
      this.isBoletoInitiated = true
    }
  }

  boletoInput(name) {
    return {
      value: this.boleto[name],
      onChange: v => (this.boleto[name] = v),
    }
  }

  isPixInitiated = false

  initPix() {
    if (!this.isPixInitiated) {
      if (
        !this.pixObject.document_value &&
        ['cpf', 'cnpj'].includes(this.leaderParticipant.document_type)
      ) {
        this.pixObject.document_type = this.leaderParticipant.document_type
        this.pixObject.document_value = this.leaderParticipant.document_value
      }
      this.isPixInitiated = true
    }
  }

  pixInput(name) {
    return {
      value: this.pixObject[name],
      onChange: v => (this.pixObject[name] = v),
    }
  }

  debitInput(name) {
    return {
      value: this.debit[name],
      onChange: v => (this.debit[name] = v),
    }
  }

  creditInput(name) {
    return {
      value: this.credit[name],
      onChange: v => (this.credit[name] = v),
    }
  }

  get boletoInvalid() {
    if (!this.boleto.document_type) return true
    if (!this.boleto.document_value) return true
    if (this.boleto.instalments < 1) return true
    if (!isDocumentValid(this.boleto.document_value, this.boleto.document_type)) return true
    return false
  }

  get pixInvalid() {
    if (!this.pixObject.document_type) return true
    if (!this.pixObject.document_value) return true
    if (!isDocumentValid(this.pixObject.document_value, this.pixObject.document_type)) return true
    return false
  }

  get debitInvalid() {
    const state = this.debit
    if (state.number.length < 15) return true
    if (!state.name) return true
    if (state.expiration.length < 5) return true
    return false
  }

  get creditInvalid() {
    const state = this.credit
    if (state.number.length < 17) return true
    if (!/^[0-9 ]+$/.test(state.number)) return true
    if (!state.name) return true
    if (state.name.length >= 26) return true
    if (!/^[a-zA-Z\s]*$/.test(state.name)) return true
    if (state.instalments < 1) return true
    if (state.expiration.length < 5) return true
    if (state.cvv.length < 3) return true

    if (this.eventCurrency === 'BRL') {
      if (!this.isInternationalCreditCard && !isPhoneValid(state.phone)) return true
    }
    if (this.eventCurrency === 'BRL' || !this.isInternationalCreditCard) {
      if (!this.hideDocsByCurrency) return false
      if (
        !['cpf', 'cnpj', 'rg', 'passport', 'birth_certificate', 'cnh'].includes(
          this.leaderParticipant.document_type
        )
      ) {
        if (!isDocumentValid(state.document_value, state.document_type)) return true
      }
    }
    return false
  }

  updateParticipantFieldsOptionals() {
    const participantOptionals = this.participantOptionals
    const participants = this.participants
    const optionalsStore = this.optionalsStore

    this.participants = participants.map(participant => {
      let { fields, ...newParticipant } = participant
      if (participantOptionals) {
        const participantOptionalsResult = participantOptionals.find(
          ({ participantId }) => participantId === participant.id
        )
        if (participantOptionalsResult) {
          const { optionals } = participantOptionalsResult
          optionalsStore.forEach(({ id }) => {
            const { [id]: removed, ...newFields } = fields
            fields = newFields
          })
          optionals.map(
            ({ optionalId, optionId, amount_in_cents }) =>
              (fields[optionalId] = { optionId, amount_in_cents })
          )
        }
      }
      return {
        ...newParticipant,
        fields,
      }
    })
  }

  setOptionals() {
    this.optionalsStore = this.event.optionals
  }

  buildParticipants() {
    this.setOptionals()
    this.participants = []
    Object.entries(this.cart).forEach(([categoryId, qty]) => {
      for (let i = 0; i < qty; i++) {
        this.participants.push(this.buildParticipant(Number(categoryId)))
      }
    })
  }

  regenerateCodes() {
    this.participants.forEach(el => {
      el.id = generateCode()
    })
  }

  removeParticipant(id) {
    this.participantsShowForm[id] = false
    // seta apenas o document_value vazio para a validação falhar
    this.participants.find(p => p.id === id).document_value = ''
  }

  getParticipants() {
    return this.participants
  }

  couponUsedForCategoryId(categoryId) {
    const coupons = this.categoryById(categoryId).list_of_coupons
    return this.enabledCoupons.filter(coupon => coupons.includes(coupon)).pop() || null
  }

  get isDocRequired() {
    return this?.event?.document_types_for_events_required ?? true
  }

  get hideDocFields() {
    return this?.event?.documents.length !== 0 ?? true
  }

  get hideDocsByCurrency() {
    if (this?.event?.currency === undefined) return true
    return this?.event?.currency === 'BRL' ?? false
  }

  get eventCurrency() {
    return this?.event?.currency
  }

  buildParticipant(categoryId) {
    return {
      id: generateCode(),
      name: '',
      email: '',
      document_type: '',
      document_value: '',
      participant_id: null,
      coupon: this.couponUsedForCategoryId(categoryId),
      categoryLabel: this.categoryLabel(categoryId),
      discount_category: this.categoryLabel(categoryId),
      amount: 0,
      categoryId: categoryId,
      discount_id: categoryId,
      discount_percentage_value: this.categoryById(categoryId).percentage,
      discount_amount_value: this.categoryById(categoryId).amount,
      fields: {},
      ddi: '+55',
      participantErrors: {
        name: '',
        email: '',
        document_type: '',
        document_value: '',
      },
      fieldsErrors: {},
    }
  }

  getParticipant(id) {
    return this.participants.find(participant => participant.id === id)
  }

  updateParticipantDDI(id, ddi) {
    this.participants = this.participants.map(participant =>
      participant.id === id ? { ...participant, ddi: ddi } : participant
    )
  }

  categoryById(categoryId) {
    return this.categories.find(c => c.id === categoryId) || {}
  }

  categoryLabel(categoryId) {
    return this.categoryById(categoryId).name
  }

  categoryPrice(categoryId) {
    return (this.categoryList.find(c => c.id === categoryId) || {}).price
  }

  setLeaderPosition(position) {
    this.leaderPosition = position
  }

  get canShowSameParticipantToggle() {
    return (
      this.more_than_one_enrollment_per_person &&
      this.make_group_form_fill_easier &&
      this.participants.length > 1
    )
  }

  get more_than_one_enrollment_per_person() {
    return this.event.more_than_one_enrollment_per_person
  }

  get make_group_form_fill_easier() {
    return this.event.make_group_form_fill_easier
  }

  get participantsInput() {
    const that = this
    return this.participants
      .map((p, index) => {
        p.position = index + 1
        p.isLeader = this.leaderPosition === p.position
        p.participantInput = name => {
          return {
            value: p[name],
            onChange(v) {
              p[name] = v
            },
            error: that.showErrors && p.participantErrors[name],
          }
        }
        p.fieldsInput = {
          value: p.fields,
          onChange(fields) {
            p.fields = fields
          },
          errors: that.showErrors && p.fieldsErrors,
        }
        p.setParticipantById = async id => {
          const emptyEnrollment = this.emptyEnrollment
          let data
          if (id) {
            that.toggleLoadingParticipant()
            const eventId = this.event.id
            data = await api.getParticipantData(eventId || fixture.id, this.userData.data.id, id)
            data.fields = mapExistingFields(data.fields, this.event.fields)
            that.toggleLoadingParticipant()
          }
          const participant = data || emptyEnrollment
          p.participant_id = participant.id || null
          id = p.id
          Object.assign(p, participant)
          p.id = id
          p.fields = Object.assign({}, p.fields)
        }
        return p
      })
      .slice(0, this.isSameParticipant ? 1 : undefined)
  }

  get emptyEnrollment() {
    return {
      name: '',
      email: '',
      document_type: '',
      document_value: '',
      participant_id: null,
      fields: {},
    }
  }

  get hasParticipantsInvalid() {
    const invalid = p =>
      isParticipantInvalid(p, this.event.documents) || hasInvalidField(p, this.fields)
    return this.participantsInput.some(invalid)
  }

  get fee() {
    return this.event.addition_percentage_fee
  }

  get event_id() {
    return this.event.id
  }

  get organization_id() {
    return this.event && this.event.organization.id
  }

  get title() {
    return this.event.title
  }

  get payment_method_id() {
    const { id } = this.currentPaymentMethod || {}
    return id
  }

  get payment_method() {
    const { payment_method } = this.currentPaymentMethod || {}
    return payment_method
  }

  get payment_type() {
    const { payment_type } = this.currentPaymentMethod || {}
    return payment_type
  }

  get isFree() {
    return this.event.free || (this.qtys > 0 && this.sum === 0)
  }

  get hasOptional() {
    return this.optionalsStore?.length && this.optionalsStore?.length !== 0
  }

  get allowAnonymous() {
    return this.event.allow_enrollment_without_login
  }

  get documents() {
    return this.event.documents
  }

  get fields() {
    return this.event.fields || []
  }

  get stepsLabels() {
    const labels =
      this.isFree && this.hasOptional
        ? ['Inscrições', 'Participantes', 'Opcionais', 'Pagamento']
        : this.isFree
        ? ['Inscrições', 'Participantes']
        : this.hasOptional
        ? ['Inscrições', 'Participantes', 'Opcionais', 'Pagamento']
        : ['Inscrições', 'Participantes', 'Pagamento']
    return labels
  }

  stepsIndexAtLocation(location) {
    const urlMap = {
      '': 'Inscrições',
      tickets: 'Inscrições',
      participants: 'Participantes',
      payment: 'Pagamento',
      optionals: 'Opcionais',
      done: 'Pagamento',
      pixDocuments: 'Pagamento',
    }
    const locationLabel = urlMap[location]
    return this.stepsLabels.indexOf(locationLabel)
  }

  get groupDiscount() {
    return this.event.group_discount
  }

  get groupDiscountMessages() {
    return this.groupDiscount && this.groupDiscount.messages
  }

  get categories() {
    return this.event.categories || []
  }

  get cartIds() {
    return Object.keys(this.cart).map(Number)
  }

  get cartCategories() {
    const addQty = o => {
      o.qty = this.cart[o.id] || 0
      return o
    }

    return this.categories
      .filter(c => this.cart[c.id] > 0) // @DEPRECATED
      .slice() // clone do array resultante
      .map(o => Object.assign({}, o)) // clone de cada elemento do array
      .map(addQty)
  }

  get totalParticantsOptionals() {
    const participantOptionals = this.getParticipantOptionals
    let totalOptionals = []
    if (participantOptionals) {
      const optionals = participantOptionals.map(({ optionals }) =>
        optionals.map(optional => optional.amount_in_cents).reduce((a, b) => a + b, 0)
      )
      totalOptionals = optionals
    }
    return totalOptionals.reduce((a, b) => a + b, 0)
  }

  get qtdeParticantsOptionals() {
    const participantOptionals = this.getParticipantOptionals
    let qtdeOptionals = []
    if (participantOptionals) {
      const optionals = participantOptionals.map(({ optionals }) =>
        optionals.map(optional => optional.qtde).reduce((a, b) => a + b, 0)
      )
      qtdeOptionals = optionals
    }
    return qtdeOptionals.reduce((a, b) => a + b, 0)
  }

  calcPriceForPaymentMethod(paymentMethodPrice) {
    const addPrice = o => {
      o.price = paymentMethodPrice
      return o
    }

    const applyDiscount = o => {
      // if (o.list_of_coupons.includes(this.coupon)) {
      o.price =
        paymentMethodPrice -
        (paymentMethodPrice * (o.percentage || 0)) / 100 -
        Math.floor((o.amount || 0) * 100)
      // }
    }

    function decorate(o) {
      addPrice(o)
      applyDiscount(o)
      return o
    }

    const decoratedCartCategories = this.cartCategories.map(decorate)

    const totalOptionals = this.totalParticantsOptionals
    let total =
      decoratedCartCategories.map(c => c.price * c.qty).reduce((a, b) => a + b, 0) + totalOptionals

    const leaderDiscount = calcLeaderDiscount(this.groupDiscount, decoratedCartCategories)
    const groupDiscount = calcGroupDiscount(
      this.groupDiscount,
      decoratedCartCategories,
      leaderDiscount
    )
    total -= leaderDiscount
    total -= groupDiscount

    return total
  }

  get selectedCategories() {
    return this.categoryList.filter(c => this.cartIds.includes(c.id))
  }

  get categoriesVisibleOrHasCoupon() {
    const hasCoupon = o =>
      o.list_of_coupons
        .map(c => c.toUpperCase())
        .some(coupon => this.enabledCoupons.includes(coupon))

    return this.categories.filter(o => o.visible || hasCoupon(o))
  }

  get qtys() {
    return Object.values(this.cart).reduce((a, b) => a + b, 0) + this.qtdeParticantsOptionals
  }

  get empty() {
    return this.qtys === 0
  }

  get sum() {
    const leaderDiscount = calcLeaderDiscount(this.groupDiscount, this.selectedCategories)
    const group = calcGroupDiscount(this.groupDiscount, this.selectedCategories, leaderDiscount)
    const totalOptionals = this.totalParticantsOptionals

    const sum =
      totalOptionals +
      this.categoryList.map(c => c.price * c.qty).reduce((a, b) => a + b, 0) -
      leaderDiscount -
      group

    return sum < 0 ? 0 : sum
  }

  get hasItems() {
    return !this.empty
  }

  get vacancies() {
    if (this.event.maximum_group_enrollments === null) return this.event.vacancies
    const vacancies = Math.min(this.event.maximum_group_enrollments, this.event.vacancies)
    return vacancies
  }

  get isTicket() {
    return this.event.is_ticket
  }

  get vacanciesAvailable() {
    return this.vacancies - this.qtys
  }

  setPaymentMethodId(id) {
    const isBrazilian = this?.event?.currency === 'BRL'

    const availableMethods = this.freeOptional() ? defaultPaymentMethods : this.paymentMethods

    const filteredMethods = isBrazilian
      ? availableMethods
      : availableMethods.filter(o => o.payment_method !== 'pix')

    this.currentPaymentMethod = filteredMethods.find(o => String(o.id) === String(id))
  }

  get boletoDueDate() {
    return this.selectedPaymentMethod?.boleto_due_at
  }

  get firstPaymentMethod() {
    return this.paymentMethods[0] || {}
  }

  get selectedPaymentMethod() {
    return this.currentPaymentMethod || this.firstPaymentMethod
  }

  get selectedPaymentMethodAmountInCents() {
    return this.selectedPaymentMethod?.amount_in_cents || 0
  }

  get hasCoupon() {
    return this.categories.some(c => c.list_of_coupons.length)
  }

  get cartDisabled() {
    const minimum_group_enrollments = this.event.minimum_group_enrollments || 0
    const hasTickets = this.categoryList.some(category => category.qty > 0)
    return this.empty || this.qtys < minimum_group_enrollments || !hasTickets
  }

  setCartAt(id, v) {
    this.cart[id] = v
    if (v <= 0) delete this.cart[id]
  }

  addCupon(value) {
    const category = this.categories.find(o => o.list_of_coupons.includes(value))
    if (category && (category.vacancies === null || category.vacancies > 0)) {
      const keys = Object.keys(this.cart)
      if (keys.length > 0) keys.forEach(key => delete this.cart[key])
      this.setCartAt(category.id, category.min_tickets || 1)
      this.buildParticipants()
    }
  }

  get categoryList() {
    const addQty = o => (o.qty = this.cart[o.id] || 0)

    const addLimit = o => {
      o.limit = Math.min(
        o.vacancies ?? this.vacancies,
        this.vacanciesAvailable + o.qty,
        this.vacancies,
        o.max_tickets ?? this.vacancies
      )
      if (o.list_of_coupons.length > 0) {
        if (!this.enabledCoupons.some(coupon => o.list_of_coupons.includes(coupon))) {
          o.limit = 0
        }
      }
      o.soldOut = (o.vacancies ?? this.vacancies) === 0 ? true : false
    }

    const addOnChange = o =>
      (o.onChange = v => {
        this.setCartAt(o.id, v)
        this.buildParticipants()
      })

    const addPrice = o => (o.price = this.selectedPaymentMethodAmountInCents)

    const applyDiscount = o => {
      // if (o.list_of_coupons.includes(this.coupon)) {
      o.price =
        this.selectedPaymentMethodAmountInCents -
        (this.selectedPaymentMethodAmountInCents * (o.percentage || 0)) / 100 -
        Math.floor((o.amount || 0) * 100)
      // }
    }

    function decorate(o) {
      o = Object.assign({}, o) // clone to dont mutate original event object
      addQty(o)
      addLimit(o)
      addOnChange(o)
      addPrice(o)
      applyDiscount(o)
      return o
    }

    return this.categoriesVisibleOrHasCoupon.map(decorate)
  }

  setCoupon(coupon) {
    this.coupon = coupon.trim().toUpperCase()
  }

  removeCouponCategoriesFromCart() {
    this.cartIds
      .filter(id =>
        this.categories.some(c => c.id === id && c.list_of_coupons.includes(this.coupon))
      )
      .map(id => delete this.cart[id])
  }

  validateCoupon(coupon) {
    return this.categories
      .map(c => c.list_of_coupons)
      .reduce((acc, val) => acc.concat(val), [])
      .map(c => c.toUpperCase())
      .includes(coupon)
  }

  freeOptional() {
    return this.isFree && this.hasOptional && this.sumWithFee !== 0
  }

  get paymentMethods() {
    return this.event.payment_methods || []
  }

  get paymentMethodsWithPrice() {
    const isBrazilian = this?.event?.currency === 'BRL'

    const addPrice = o => {
      o.price = this.calcPriceForPaymentMethod(o.amount_in_cents)
      return o
    }

    const filterMethods = methods =>
      methods.filter(o => isBrazilian || o.payment_method !== 'pix').map(addPrice)

    return this.freeOptional()
      ? filterMethods(defaultPaymentMethods)
      : filterMethods(this.paymentMethods)
  }

  setEvent(event) {
    this.event = event
    this.buildParticipants()
    return event
  }

  get hasPassFee() {
    return this.event.pass_fee
  }

  get minimun_fee_cents() {
    return 190
  }

  calcFeeInCents(amountInCents) {
    let amountFeePercentage = this.selectedPaymentMethod.amount_fee_percentage

    // Quando o evento é gratutio, deve pegar a taxa de repasse do event
    if (this.event.free) amountFeePercentage = this.event.free_event_fee_percentage

    const amount = Math.floor((amountInCents * amountFeePercentage) / 100)

    if (amount > 0 && amount < this.minimun_fee_cents) return this.minimun_fee_cents

    return amount
  }

  get freeEventPercentage() {
    return this.event.free_event_fee_percentage
  }

  loadEvent(eventId) {
    const event_id = parse(document.location.search).event_id // 30371 // 11774 //29163
    if (event_id) {
      return api.getEvent(event_id).then(event => this.setEvent(event))
    } else {
      // load fixture event only in development
      return Promise.resolve(DEV ? this.setEvent(fixture) : {})
    }
  }

  async signIn(email, password) {
    try {
      const response = await api.signIn(email, password)
      this.userData = response
      GA4.UserId(this.userData.data.id)
      sessionStorage.setItem('userData', JSON.stringify(response))
      const eventId = this.event.id
      const participants = await api.getParticipantList(eventId || fixture.id, response.data.id)
      this.participantsList = participants
      sessionStorage.setItem('userDataParticipantsList', JSON.stringify(this.participantsList))
      GA4.Login()
      return response.data.id
    } catch (e) {
      throw e?.json || e
    }
  }

  async signUp(form) {
    try {
      const response = await api.signUp(form)
      this.userData = response
      GA4.UserId(this.event.integrations.google_analytics, this.userData.data.id)
      sessionStorage.setItem('userData', JSON.stringify(response))

      if (response.participants?.[0])
        this.participantsList = [Object.values(response.participants[0])]
      else this.participantsList = []

      sessionStorage.setItem('userDataParticipantsList', JSON.stringify(this.participantsList))
      GA4.Create()
      return response.data.id
    } catch (e) {
      if (e.message === 'Failed to fetch') throw new Error('Algo deu errado. Tente mais tarde.')
      throw e?.json || e
    }
  }

  async getCardInternational() {
    const payload = {
      ...this.enrollment,
      payment: {
        payment_method_id: !this.event.free ? this.payment_method_id : null,
        payment_method: this.payment_method,
        number_of_instalments: 1,
        amount: this.sumWithFee / 100,
      },
      event: this.event,
    }

    const customer_code = await customerCode(
      payload.enrollment.code,
      payload.event_id,
      Math.round(this.sumWithFee)
    )

    const data = {
      amount: Math.round(this.sumWithFee),
      free: this.event.free,
      installments: this.credit.instalments,
      isInstallment: true,
      metadata: {
        z: Object.assign(this.metadata, payload),
      },
      customer_code,
    }

    data.metadata.front_end = 'fluxo-inscricao'
    data.metadata.host_url = HOST_URL

    const log = {
      ...data,
    }

    const enrollment = await validateEnrollment({ payload: payload })
    await postLog(
      log,
      `PAYLOAD INTERNACIONAL PARCELADO: ${this.enrollment.enrollment.code}`,
      'ccbdbafd-2c83-4a78-bab7-11b557922f58'
    )
    if (enrollment.valid && enrollment.message.length === 0)
      return Services.pagarmeCard(data).then(transaction => {
        return (this.pagarmeTransaction = transaction)
      })
    else throw new Error(enrollment.message)
  }

  async getPagarmeTransaction() {
    const amount = Math.trunc(this.sumWithFee) // amount in cents
    const payload = {
      ...this.enrollment,
      payment: {
        payment_method_id: !this.event.free ? this.payment_method_id : null,
        payment_method: this.payment_method,
        number_of_instalments: this.credit.instalments,
        amount: amount / 100,
      },
      event: this.event,
    }

    if (this.payment_type === 'cartao parcelado acrescimo') {
      const fee_amount = this.hasPassFee ? this.calcFeeInCents(this.sum) : 0
      const additional_value = amount - this.sum - fee_amount
      payload.payment.amount = amount / 100
      payload.payment.additional_value = additional_value / 100
    }

    const customer_code = await customerCode(
      payload.enrollment.code,
      payload.event_id,
      Math.round(this.sumWithFee)
    )

    const data = {
      amount,
      free: this.event.free,
      card_number: this.credit.number.replace(/\s+/g, ''),
      card_cvv: this.credit.cvv,
      card_expiration_date: this.credit.expiration.replace(/\D/g, ''),
      card_holder_name: this.credit.name,
      customer: {
        email: this.leaderParticipant.email,
        document_number: this.credit.document_value || this.leaderParticipant.document_value,
      },
      installments: this.credit.instalments,
      phone: this.formatedPhone(),
      country: this.credit.country,
      metadata: {
        z: Object.assign(this.metadata, payload),
      },
      customer_code,
    }

    data.metadata.front_end = 'fluxo-inscricao'
    data.metadata.host_url = HOST_URL

    const log = {
      ...data,
      card_number: `**** **** **** ****`,
      card_cvv: `****`,
      card_expiration_date: `****`,
    }

    const enrollment = await validateEnrollment({ payload: payload })
    await postLog(
      log,
      `PAYLOAD CARTAO: ${this.enrollment.enrollment.code}`,
      'ccbdbafd-2c83-4a78-bab7-11b557922f58'
    )
    if (enrollment.valid && enrollment.message.length === 0)
      return Services.pagarmeCard(data).then(transaction => {
        return (this.pagarmeTransaction = transaction)
      })
    else throw new Error(enrollment.message)
  }

  formatedPhone() {
    const phone = this.credit.phone.replace(/\D/g, '')
    return phone.startsWith('55') ? phone.slice(2) : phone
  }

  async enrollByCard() {
    const transaction = await this.getPagarmeTransaction()
    return transaction
  }

  get metadata() {
    return {
      organization_id: this.organization_id,
      event_id: this.event_id,
      payment_method_id: this.payment_method_id,
      main_enrollment_code: this.leaderParticipant.id,
      group_size: this.participants.length,
    }
  }

  get optionals() {
    return this.optionalsStore
  }

  setOptionalsStore(optionals) {
    this.optionalsStore = optionals
  }

  async enrollByFree() {
    const payload = {
      ...this.enrollment,
      payment: {
        payment_method: 'free',
      },
      event: this.event,
    }

    const enrollment = await validateEnrollment({ payload: payload })

    if (enrollment.valid && enrollment.message.length === 0)
      return await Services.free({
        transaction: {
          metadata: {
            z: payload,
            front_end: 'fluxo-inscricao',
          },
        },
      })
    else throw new Error(enrollment.message)
  }

  async enrollByDebit(cielo) {
    const payload = {
      ...this.enrollment,
      payment: {
        payment_method_id: this.payment_method_id,
        number_of_instalments: 1,
        amount: cielo.Payment.Amount / 100,
        banner: cielo.Payment.DebitCard.Brand,
        tid: cielo.Payment.Tid + ' ' + cielo.Payment.PaymentId,
      },
      cielo_log: cielo,
    }
    return api.enrollDebit(payload)
  }

  async enrollByBoleto() {
    const transaction = await this.getPagarmeBoleto()
    return transaction
  }

  pagarmeBoleto = {}

  async getPagarmeBoleto() {
    // if (this.pagarmeBoleto) {
    //   return Promise.resolve(this.pagarmeBoleto)
    // }

    const payload = {
      ...this.enrollment,
      payment: {
        payment_method_id: this.payment_method_id,
        number_of_instalments: this.boleto.instalments,
        amount: this.sumWithFee / 100,
      },
      event: this.event,
    }

    const data = {
      amount: Math.trunc(this.sumWithFee / this.boleto.instalments),
      boleto_expiration_date: this.boletoDueDate,
      boleto_instructions: this.event.boleto_instructions,
      soft_descriptor: this.event.organization.slug.substr(0, 13),
      customer: {
        name: this.boleto.name,
        email: this.leaderParticipant.email,
        document_number: this.boleto.document_value,
      },
      metadata: { z: Object.assign(this.metadata, payload) },
    }

    data.metadata.front_end = 'fluxo-inscricao'
    data.metadata.host_url = HOST_URL

    const enrollment = await validateEnrollment({ payload: payload })

    if (enrollment.valid && enrollment.message.length === 0)
      return (this.pagarmeBoleto = await Services.pagarmeBoleto(data))
    else throw new Error(enrollment.message)
  }

  cielo = null

  sendDebit() {
    const [mes, ano] = this.debit.expiration.split('/')
    const expirationDate = `${mes}/20${ano}`
    const debit = {
      orderId: this.debit.tid,
      name: this.debit.name,
      amount: this.sumWithFee, // 500 R$ 5,00 in cents
      cardNumber: this.debit.number,
      expirationDate,
    }
    return Services.cieloDebit(debit).then(response => (this.cielo = response))
  }
}

window.Store = /*window.Store ||*/ new Store()

let s = store(window.Store)

let ps = new Proxy(s, {
  get(obj, prop) {
    prop = obj[prop]
    if (typeof prop === 'function') prop = prop.bind(obj)
    return prop
  },
})

export default window.store = ps
